import { imageUrlAdapter } from '@/adapters/constructor/utils/image';

export default ({ data }) => {
  return {
    text: data.text,
    staff: {
      image: imageUrlAdapter(data.staff_banner?.image),
      name: data.staff_name,
      email: data.staff_email,
      position: data.staff_position,
    },
  };
};
